<template>
    <div class="slm-link-carousel__post">
        <a class="slm-link-carousel__post--link" :href="postUrl" @[isButton&&`click`].prevent="redirectUserToRandomPost">
            <div class="slm-link-carousel__post--image-wrapper">
                <div 
                    v-if="post.image && post.image.src" 
                    class="slm-link-carousel__post--image"
                    :class="post.color && useOverlay ? post.color : ''"
                    :style="{ backgroundImage: 'url(' + post.image.src + ')' }"
                >
                    <span class="show-for-sr">{{post.image.alt}}</span>
                </div>
                <span v-if="(useOverlay && post.icon && post.icon != 'none')" class="slm-link-carousel__post--type" :class="post.icon"></span>
            </div>
            <h2 class="slm-link-carousel__post--title">{{post.title}}</h2>
            <p class="slm-link-carousel__post--description">{{post.description}}</p>
        </a>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'post-component',
    props: ['post', 'posts'],
    computed: {
        useOverlay() {
            return this.$store.state.useOverlay;
        },
        postUrl() {
            return this.post.url ? this.post.url : "#";
        },
        isButton() {
            return this.post.url ? false : true;
        }
    },
    methods: 
    {
        getPostIds(){
            let ids = this.posts.map((item) => {
                return item.ID
            });
            return ids;
        },
        redirectUserToRandomPost () {
            if( this.postUrl === "#" ){
                const ids = this.getPostIds();
                axios.get(`/wp-json/slm/v1/random-posts/?exclude=${ids}`).then(
                    response => {
                        window.location.href = response.data;
                    }
                );
            } 
        }
    }
}
</script>