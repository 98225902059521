<template>
    <swiper
      class="slm-link-carousel__swiper slm-carousel"
      :initial-slide="(posts.length - 1)  % 2 === 0 ? (posts.length - 1) / 2 : posts.length / 2"
      :modules="swiperModules"
      :slides-per-view="'auto'"
      :centeredSlides="true"
      :space-between="20"
      :navigation="navigationOptions"
      :loop="false"
      :pagination="paginationOptions"
      @transition-end="handleDuplicates"
    >
      <swiper-slide class="slm-carousel__slide" v-for="post in posts" :key="post.url">
          <post-component :post="post" :posts="posts"/>
      </swiper-slide>
      <button class="slm-carousel__nav-button slm-link-carousel__swiper--nav-button prev">
        <span class="show-for-sr">Visa föregående slide</span>
      </button>
      <div class="slm-carousel__pagination slm-link-carousel__swiper--pagination"></div>
      <button class="slm-carousel__nav-button slm-link-carousel__swiper--nav-button next">
        <span class="show-for-sr">Visa nästa slide</span>
      </button>
    </Swiper>
</template>

<script>
import PostComponent from './components/post-component.vue';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

export default {
  name: 'page-carousel',
  data() {
    return {
      swiperModules: [Navigation, Pagination],
      navigationOptions: {
        enabled: true,
        nextEl: '.slm-link-carousel__swiper--nav-button.next',
        prevEl: '.slm-link-carousel__swiper--nav-button.prev'
      },
      paginationOptions: {
        el: '.slm-link-carousel__swiper--pagination',
        type: 'bullets',
        clickable: true,
        bulletClass: 'slm-carousel__pagination--bullet',
        bulletActiveClass: 'active',
        currentClass: 'current',
        renderBullet: (index, className) => {
          return `<span role="button" class="${className}" aria-label="Gå till slide ${index + 1}"></span>`
        }
      }
    }
  },
  computed: {
    posts() {
      return this.$store.state.posts;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.swiper && this.$refs.swiper.swiper) {
        const swiperInstance = this.$refs.swiper.swiper;
        swiperInstance.loopDestroy(); // Destroy any existing loop
        swiperInstance.params.loop = false; // Explicitly set loop to false
        swiperInstance.update(); // Update Swiper instance
      }
    });
  },
  methods: {
    handleDuplicates(swiper) {
      if (swiper.params.loop) {
        swiper.loopDestroy(); // Destroy the loop
        swiper.params.loop = false; // Ensure loop is disabled
        swiper.update(); // Update Swiper instance
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    PostComponent
  }
};
</script>